import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Navigation from "../../components/navigation";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import ReactGA from "react-ga";
import { Link } from "gatsby";
import DonationFooter from "../../components/donation-footer";
import CodeSnippet from '../../components/code-snippet'

class View extends React.Component {
	constructor(props) {
		super(props);
	}

	componentDidMount() {
		ReactGA.initialize("UA-115464417-1");
		if (!(window.location.hostname === "localhost")) {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
    }

	render() {
		return (
			<CodeSnippet

			header={'Prim\'s Algorithm | Code Tutorial'}
			subHeader={''}
			description={'Prim\'s Algorithm. Prim\'s algorithm is a minimum spanning tree algorithm that takes a graph as input and finds the subset of the edges of that graph which. form a tree that includes every vertex. has the minimum sum of weights among all the trees that can be formed from the graph.'}
			keywords={[]}
			embeddedVideo={'YIdp35eD4X0'}
			embeddedCode={'https://gist.githubusercontent.com/graphoarty/35d84251eb6080a5e08a87f606f483c5/raw/73924a737597460e4ec3c5881301c160e63cf1b7/gistfile1.txt'}		

			></CodeSnippet>
		);
	}
}

export default View;
